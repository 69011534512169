import "../carousel-init";
import "./carousel-indicators.scss";
import {
  CarouselAdditionInitializationBlock,
  forEachRegisteredCarouselController,
} from "../carousel-handles";
import { initializeSliderIndicatorHandler } from "./slider-indicator-handler";
import { currentCarouselQuerySelectorAll } from "../selector/current-selector";

const initializeSliderIndicatorElements: CarouselAdditionInitializationBlock = (
  carouselRootElement,
  controller
) => {
  for (const indicatorElement of currentCarouselQuerySelectorAll(
    carouselRootElement,
    ".carousel-indicators"
  )) {
    initializeSliderIndicatorHandler(controller, indicatorElement);
  }
};

forEachRegisteredCarouselController(initializeSliderIndicatorElements);
