import { CarouselController } from "../controller/carousel-controller";
import { CarouselSliderSlideChangeEvent } from "../slider/carousel-slider-slide-change-event";
import { CarouselSliderItemNumberChangeEvent } from "../slider/carousel-slider-item-number-change-event";
import { currentCarouselQuerySelectorAll } from "../selector/current-selector";

export const initializeSliderIndicatorHandler = (
  controller: CarouselController,
  elementRoot: HTMLElement
) => {
  const elementList = [] as HTMLElement[];

  controller.addEventListener(CarouselSliderSlideChangeEvent.KEY, (event) => {
    for (const [index, element] of elementList.entries()) {
      updateActiveClassNameOfIndicatorItemElement(event.index, index, element);
    }
  });

  controller.addEventListener(CarouselSliderItemNumberChangeEvent.KEY, () =>
    rerenderIndicatorListItems(elementRoot, controller, elementList)
  );

  initializeIndicatorList(elementRoot, controller, elementList);
};

/**
 * Initializes the indicator list:
 *
 * If there are as many indicators elements as slides, we can simply add the
 * event handlers and don't need to render them.
 *
 * If the amount of indicators does not match the amount of slides, we need to
 * re-render the indicator elements.
 *
 * @param elementRoot
 * @param controller
 * @param elementList will be mutated **in-place**
 */
const initializeIndicatorList = (
  elementRoot: HTMLElement,
  controller: CarouselController,
  elementList: HTMLElement[]
) => {
  const originalElementList = currentCarouselQuerySelectorAll(
    elementRoot,
    ":scope > li"
  );

  if (originalElementList.length !== controller.getNumberOfSlides()) {
    rerenderIndicatorListItems(elementRoot, controller, elementList);
    return;
  }

  for (const [index, indicatorItem] of originalElementList.entries()) {
    initializeIndicatorItemElement(controller, index, indicatorItem);
    elementList.push(indicatorItem);
  }
};

/**
 * Re-Renders the indicator element list.
 *
 * @param elementRoot
 * @param controller
 * @param elementList will be cleared and populated **in-place**
 */
const rerenderIndicatorListItems = (
  elementRoot: HTMLElement,
  controller: CarouselController,
  elementList: HTMLElement[]
) => {
  const bluePrint = findBluePrintElement(elementRoot);
  consumeAllElementsByRemoving(elementList);
  for (let index = 0; index < controller.getNumberOfSlides(); index += 1) {
    const indicatorItemElement = bluePrint.cloneNode() as HTMLElement;
    indicatorItemElement.id = ""; // The only id value that is allowed to be duplicated is "" (empty string)
    initializeIndicatorItemElement(controller, index, indicatorItemElement);
    elementList.push(indicatorItemElement);
    elementRoot.append(indicatorItemElement);
  }
};

const initializeIndicatorItemElement = (
  controller: CarouselController,
  index: number,
  indicatorItemElement: HTMLElement
) => {
  updateActiveClassNameOfIndicatorItemElement(
    controller.getCurrentIndex(),
    index,
    indicatorItemElement
  );
  indicatorItemElement.addEventListener("click", () => {
    controller.slideTo(index);
  });
};

const updateActiveClassNameOfIndicatorItemElement = (
  carouselIndex: number,
  indicatorItemElementIndex: number,
  indicatorItemElement: HTMLElement
) => {
  const shouldElementBeActive = carouselIndex === indicatorItemElementIndex;

  if (
    indicatorItemElement.classList.contains("active") === shouldElementBeActive
  ) {
    return;
  }

  if (shouldElementBeActive) {
    indicatorItemElement.classList.add("active");
  } else {
    indicatorItemElement.classList.remove("active");
  }
};

const findBluePrintElement = (rootElement: HTMLElement) => {
  const bluePrintElement =
    rootElement.querySelector<HTMLElement>(":scope > li");

  if (bluePrintElement !== null) {
    return bluePrintElement;
  }

  const newElement = document.createElement("li");

  // data-target property
  const slideRootElement = rootElement.closest(".carousel");
  if (slideRootElement !== null && slideRootElement.id) {
    newElement.dataset["target"] = slideRootElement.id;
  }

  return newElement;
};

/**
 * Removes all elements from the input list **in-place** and calls {@link ChildNode.remove} on them.
 */
const consumeAllElementsByRemoving = (elementList: HTMLElement[]) => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const element = elementList.shift();
    if (element === undefined) {
      return;
    }
    element.remove();
  }
};
